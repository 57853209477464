module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ElfNullEins","short_name":"ElfNullEins","start_url":"/","background_color":"#191919","theme_color":"#191919","display":"standalone","icon":"src/images/elfnulleins-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"137446bf0b37eaf22ac260aa0a8664e8"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["de"],"defaultLanguage":"de","path":"/Users/thomaseckhardt/dev/studio/elfnulleins/elfnulleins-interims-site/locales","siteUrl":"https://www.elfnulleins.com/","redirect":false,"i18nextOptions":{"debug":false,"lowerCaseLng":true,"interpolation":{"escapeValue":false},"saveMissing":true,"fallbackLng":"de"},"pages":[{"matchPath":"/:any*","getLanguageFromPath":true,"languages":[]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
