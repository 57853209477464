/**
 * This theme uses `theme-ui` under the hood.
 * @see https://theme-ui.com/
 * @see https://theme-ui.com/gatsby-plugin/
 */

// import { isString } from '../utils'

// import resolveConfig from 'tailwindcss/resolveConfig'
// import tailwindConfigExtension from '../../tailwind.config.js'

// const tailwindConfig = resolveConfig(tailwindConfigExtension)

// const {
//   theme: { screens },
// } = tailwindConfig

// const breakpoints = Object.values(screens).filter(screen => isString(screen))

// const tailwindThemeUiConfig = {
//   borderWidths,
//   breakpoints,
//   colors,
//   fonts,
//   fontSizes,
//   fontWeights,
//   letterSpacings,
//   lineHeights,
//   sizes,
//   shadows,
//   space,
//   radii,
//   zIndices,
//   styles,
//   buttons,
//   inputs,
//   transforms,
//   transitions,
// }

// import { breakpoints } from '../theme/breakpoints'
// import { colors, keof as keofColors, keef as keefColors } from '../theme/colors'
// import { shadows } from '../theme/shadows'
// import { space } from '../theme/space'
// import { sizes } from '../theme/sizes'
// import { styles } from '../theme/styles'
// import { typography } from '../theme/typography'
// import { variants } from '../theme/variants'
// import { zIndices } from '../theme/zIndices'

// export const keofTheme = {
//   colors: keofColors,
// }

// export const keefTheme = {
//   colors: keefColors,
// }
const theme = {
  // ...tailwindConfig,
  // breakpoints,
  // colors,
  // shadows,
  // space,
  // sizes,
  // styles,
  // ...typography,
  // ...variants,
  // zIndices,

  // ---------------------------------------------------------------------------
  // Configuration
  // @see https://theme-ui.com/theming#configuration-flags
  // ---------------------------------------------------------------------------
  // Enables CSS custom properties to help mitigate a flash of unstyled content
  // during rehydration
  useCustomProperties: true,
  // Adds styles defined in theme.styles.root to the <body> element along with
  // color and background-color
  useBodyStyles: false,
  // The key used for the top-level color palette in theme.colors
  initialColorModeName: 'light',
  // Initializes the color mode based on the prefers-color-scheme media query
  useColorSchemeMediaQuery: false,
  // Adds a global box-sizing: border-box style
  useBorderBox: false,
  // Persists the color mode in localStorage
  useLocalStorage: false,
}

// console.info('Tailwind Config', tailwindConfig)
// console.info('Theme UI Config', theme)

export default theme
